import React from 'react';
import logo from './logo.png';
import './App.css';
import RealOrNot from './real-or-not';

function App() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24">
      <div className="welcome-container">
          <h1 className="text-4xl font-bold mb-8">Welcome to Art or AI Game</h1>
          <img src={logo} alt="Hagia logo" className="game-logo" />
        </div>
      
      <RealOrNot/>
  </main>
  );
}

export default App;