"use client"

import React from "react"
import './real-or-not.css'
import { useState, useEffect } from "react"
import Button from "@mui/material/Button"
import { Card, CardContent, CardActions as CardFooter, CardHeader, Typography } from "@mui/material"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"

const importAll = (r: any) => {
    let images: { [key: string]: string } = {};
    r.keys().forEach((item: string) => { images[item.replace('./', '')] = r(item); });
    return images;
  };

let mockData: { id: number, imagePath: string, isReal: boolean }[] = [];
// Assuming importAll returns an object where keys are file paths and values are the image URLs
const realImages = importAll((require as any).context('./arts/RealArt', false, /\.(png|jpe?g|svg)$/));
const aiImages = importAll((require as any).context('./arts/AiArtData', false, /\.(png|jpe?g|svg)$/));

// Unique ID counter
let idCounter = 1;

// Function to push images into the mockData array with the given structure
const pushImagesToMockData = (images: { [key: string]: string }, isReal: boolean) => {
  Object.keys(images).forEach((imageKey) => {
    mockData.push({
      id: idCounter++,            // Increment the ID for each image
      imagePath: images[imageKey], // Image URL
      isReal: isReal               // true for real images, false for AI images
    });
  });
};

// Push real and AI images to mockData
pushImagesToMockData(realImages, true);  // Real images
pushImagesToMockData(aiImages, false);   // AI images

// Fisher-Yates shuffle algorithm
const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];  // Swap elements
  }
  return array;
};

export default function Component() {
  const [currentRound, setCurrentRound] = useState(0)
  const [score, setScore] = useState(0)
  const [gameOver, setGameOver] = useState(false)
  const [data, setData] = useState(mockData)
  const [askedImages, setAskedImages] = useState<{ id: number, imagePath: string, isReal: boolean, answer: boolean }[]>([]);

  useEffect(() => {
    // Shuffle the data array when the component mounts
    setData(shuffleArray([...mockData]))
  }, [])

  const handleGuess = (guess: boolean) => {
        const newUserAnswers = [...askedImages, {
          id: data[currentRound].id,
          imagePath: data[currentRound].imagePath,
          isReal: data[currentRound].isReal,
          answer: guess
        }]
        setAskedImages(newUserAnswers)

    if (guess === data[currentRound].isReal) {
      setScore(score + 1)
    }

    if (currentRound === 9) {
      setGameOver(true)
    } else {
      setCurrentRound(currentRound + 1)
    }
  }

  const resetGame = () => {
    setCurrentRound(0)
    setScore(0)
    setGameOver(false)
    setAskedImages([])
    setData(shuffleArray([...mockData]))
  }

  return (
    <Card className="art-or-ai-game">
      <CardHeader>
        <Typography variant="h5" className="game-title">Art or AI?</Typography>
      </CardHeader>
      <CardContent>
        {!gameOver ? (
          <div className="space-y-4">
            <p className="round-info">Round {currentRound + 1} of 10</p>
            <div className="image-container">
              <img
                src={data[currentRound].imagePath}
                alt={`Art piece ${currentRound + 1}`}
              />
            </div>
            <p className="text-center">Is this real art or AI-generated?</p>
          </div>
        ) : (
          <div className="game-over">
            <h2 className="text-xl font-semibold">Game Over!</h2>
            <p className="final-score">Your final score: {score} out of 10</p>
            <Table className="results-table">
              <TableHead>
                <TableRow>
                  <TableCell>Round</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Your Answer</TableCell>
                  <TableCell>Correct Answer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {askedImages.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <img src={item.imagePath} alt={`Art piece ${item.id + 1}`} />
                    </TableCell>
                    <TableCell style={{ color: item.answer === item.isReal ? 'green' : 'red' }}>{item.answer ? "Real Art" : "AI-Generated"}</TableCell>
                    <TableCell>{item.isReal ? "Real Art" : "AI-Generated"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </CardContent>
      <CardFooter className="button-container">
        {!gameOver ? (
          <>
            <Button onClick={() => handleGuess(true)} className="custom-button">🧑‍🎨 Real Art</Button>
            <Button onClick={() => handleGuess(false)} className="custom-button">🤖 AI-Generated</Button>
          </>
        ) : (
          <Button onClick={resetGame} className="custom-button">🔄 Play Again</Button>
        )}
      </CardFooter>
    </Card>
  )
}